<template>
    <div
        v-if="active && !isMobile"
        class="navbar-dropdown wishlist"
    >
        <div class="drop-content">
            <div
                v-for="product in wishlistProducts"
                :key="`drop-item-${product.code}`"
                class="item d-flex"
            >
                <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                    <div class="image">
                        <img
                            :src="product.thumbnail"
                            :alt="product.name"
                        >
                    </div>
                </router-link>
                <div class="content">
                    <div class="d-flex drop-head">
                        <router-link
                            :to="{ name: 'Produs', params: { slug: product.slug } }"
                        >
                            <p class="sm-sbold title">
                                {{ product.name }}
                            </p>
                        </router-link>
                        <x-icon
                            class="close"
                            @click="removeItem(product.id)"
                        />
                    </div>
                    <div class="d-flex info">
                        <p
                            v-dompurify-html="price(product)"
                            class="sm-sbold"
                        />
                        <add-to-cart
                            :is-icon="isResponsive ? 'icon' : ''"
                            :stock="product.stock_furnizor"
                            type="link"
                            :product="cartProduct(product)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="drop-footer nb">
            <div
                v-if="isEmpty"
                class="empty"
            >
                <div class="d-flex heart">
                    <heart-icon />
                    <p class="sm-sbold">
                        {{ $t('header.dropdown.noItemsInWishlist') }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="!isEmpty"
            class="drop-action nb"
        >
            <b-button
                variant="primary"
                class="sm"
                :to="{ name: 'Favorite' }"
            >
                <p class="sm-sbold">
                    {{ $t('header.dropdown.seeWishlistDetails') }}
                </p>
            </b-button>
        </div>
    </div>
</template>

<script>
    import HeartIcon from 'vue-feather-icons/icons/HeartIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';

    import AddToCart from '@/components/controls/product/AddToCart';
    import { splitedPrice } from '@/services/getSplitedPrice';

    export default {
        name: 'NavbarWishlistDropdown',
        components: {
            XIcon,
            HeartIcon,
            AddToCart,
        },

        props: {
            active: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('wishlist', { wishlistProducts: 'products' }),
            isEmpty() {
                return Object.keys(this.wishlistProducts) == 0;
            },
            totalText() {
                let text = '';
                if (Object.keys(this.wishlistProducts).length > 0) {
                    text = 'Total elemente: ' + Object.keys(this.wishlistProducts).length;
                }
                return text;
            },
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
            isResponsive() {
                return this.$screen.width < 768;
            },
        },
        methods: {
            ...mapActions('wishlist', ['removeItem']),
            price(product) {
                return splitedPrice(product.current_price);
            },
            formatPrice(price) {
                return splitedPrice(price);
            },

            cartProduct(product) {
                let cartProduct = {
                    id: product.id,
                    thumbnail: product.thumbnail,
                    name: product.name,
                    price: product.price,
                    discount_percentage: product.discount_percentage,
                    current_price: product.current_price,
                };
                return cartProduct;
            },
        },
    };
</script>
