<template>
    <li
        class="nav-item nav-drop-item wishlist"
        @mouseover="openPopup()"
        @mouseleave="closePopup()"
    >
        <router-link
            :to="{ name: 'Favorite' }"
            variant="text"
            class="btn icon link"
            aria-label="Produse favorite"
        >
            <heart-icon />
            <span
                v-if="count > 0"
                class="count"
                aria-label="Produse favorite"
            >{{ count }}</span>
        </router-link>
        <navbar-wishlist-dropdown :active="showPopup" />
    </li>
</template>

<script>
    import HeartIcon from 'vue-feather-icons/icons/HeartIcon';
    import { mapState } from 'vuex';

    import NavbarWishlistDropdown from './NavbarWishlistDropdown';

    export default {
        name: 'WishlistItemNavbar',
        components: {
            HeartIcon,
            NavbarWishlistDropdown,
        },
        data() {
            return {
                count: 0,
                showPopup: false,
                popupReached: false,
            };
        },
        computed: {
            ...mapState('wishlist', ['products']),
        },
        methods: {
            initialize() {
                this.count = Object.keys(this.products).length;
            },
            closePopup() {
                this.popupReached = false;
                setTimeout(() => {
                    if (!this.popupReached) {
                        this.showPopup = false;
                    }
                }, 150);
            },
            openPopup() {
                if(this.$route.name != 'Favorite'){
                    this.showPopup = true;
                    this.popupReached = true;
                }
            },
        },
        mounted() {
            this.initialize();
        },
        watch: {
            products: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
            $route() {
                this.showPopup = false;
            },
        },
    };
</script>
<style scoped lang="scss">
.wishlist {
  .count {
    background-color: $red;
  }
}
</style>
